import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";

const Video = loadable(() => import("../base/VideoPlayer"));

const VideoContainer = ({ url, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (loaded && typeof url === "string") return <Video url={url} {...props} />;

  return <div {...props} />;
};

export default VideoContainer;
